import { Injectable } from '@angular/core';
import { Config, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})

export class LanguageService {

  storedLanguage: string;
  selectedLanguage: string;

  constructor(
    private translate: TranslateService,
    private config: Config,
    private storage: Storage,
    private platform: Platform,
  ) {
  }

  async initTranslate() {
    // Set the default (fallback) language for translation strings, and the current language.
    await this.platform.ready();
    const language = await this.storage.get('language');

    this.translate.addLangs(['nl-NL', 'en-GB', 'en-US', 'fr-FR']);
    this.translate.setDefaultLang('en-GB');

    if (language && language !== '') {
      await this.useLanguage(language, false);
    } else {
      await this.useDefaultAppLang();
    }

    this.translate.onLangChange.subscribe((event) => {
      this.config.set('backButtonText', event.translations.BACK);
    });
  }

  async useDefaultAppLang() {
    if (this.translate.getBrowserCultureLang() !== undefined && this.translate.getLangs().indexOf(this.translate.getBrowserCultureLang()) > -1) {
      await this.useLanguage(this.translate.getBrowserCultureLang());
    } else {
      await this.useLanguage(this.translate.getDefaultLang());
    }
  }

  async useLanguage(language: string, saveSetting = true) {
    if (saveSetting) {
      await this.storage.set('language', language);
    }
    this.translate.use(language);
  }

}

import { Component } from '@angular/core';

import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private statusBar: StatusBar,
    private languageService: LanguageService,
  ) {
    this.languageService.initTranslate().then(() => {
      this.initializeApp();

      if (!localStorage.getItem('dont-show')) {
        this.showDimmingTimerAlert();
      }

    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.backgroundColorByHexString('#56c06e');
    });
  }

  async showDimmingTimerAlert() {
    this.translate.get('ALERT_MESSAGES.DIM_SCREEN').subscribe((data) => {
      this.showAlert(data);
    });
  }

  async showAlert(data) {
    const alert = await this.alertCtrl.create({
      header: data.header,
      message: data.message,
      buttons: [
        {
          text: data.buttonOk,
        },
        {
          text: data.buttonCancel,
          handler: () => {
            localStorage.setItem('dont-show', 'true');
          },
        },
      ],
    });

    await alert.present();
  }

}

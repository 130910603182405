import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ReminderSound } from '../../models/reminder-sound';

@Component({
  selector: 'app-alarm-selector',
  templateUrl: './alarm-selector.page.html',
  styleUrls: ['./alarm-selector.page.scss'],
})
export class AlarmSelectorPage implements OnInit {

  constructor(private modalCtrl: ModalController, private navParams: NavParams) {

  }

  vibrate: boolean;
  alarmType: string;

  ngOnInit() {
    this.vibrate = this.navParams.get('vibrate');
    this.alarmType = this.navParams.get('alarm_type') === 0 ? 'none' : this.navParams.get('alarm_type') === 1 ? 'stdsound' : 'bell';
  }

  vibrateTapped() {
    this.vibrate = !this.vibrate;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  done() {
    const obj = {vibrate: this.vibrate, alarmType: ReminderSound.BellSound, alarm: 'Bell'};
    if (this.alarmType === 'stdsound') {
      obj.alarmType = ReminderSound.StandardSound;
      obj.alarm = 'Standard Sound';
    } else if (this.alarmType === 'none') {
      obj.alarmType = ReminderSound.NoSound;
      obj.alarm = 'None';
    }
    this.modalCtrl.dismiss(obj);
  }

}

import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SocialShareService {

  constructor(
    private socialSharing: SocialSharing,
    private translate: TranslateService,
  ) {
  }

  async share() {
    this.socialSharing.share(
      await this.translate.get('SOCIAL_SHARE_MESSAGE').toPromise(),
      await this.translate.get('THE_SITTING_STILL_APP').toPromise(),
      null,
      'https://www.elinesnel.com/sitting-still-app/');
  }

}

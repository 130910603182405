import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './tabs/tabs.module#TabsPageModule',
  },
  {
    path: 'notes',
    loadChildren: './notes/notes.module#NotesPageModule',
  },
  {
    path: 'statistics',
    loadChildren: './statistics/statistics.module#StatisticsPageModule',
  },
  {
    path: 'reminders',
    loadChildren: './reminders/reminders.module#RemindersPageModule',
  },
  {
    path: 'meditate',
    loadChildren: './meditate/meditate.module#MeditatePageModule',
  },
  {
    path: 'reminders-create',
    loadChildren: './reminders/reminders-create/reminders-create.module#RemindersCreatePageModule',
  },
  {
    path: 'meditate-selector',
    loadChildren: './reminders/meditate-selector/meditate-selector.module#MeditateSelectorPageModule',
  },
  {
    path: 'alarm-selector',
    loadChildren: './reminders/dialogs/alarm-selector/alarm-selector.module#AlarmSelectorPageModule',
  },
  {
    path: 'meditate-personalized',
    loadChildren: './meditate/meditate-personalized/meditate-personalized.module#MeditatePersonalizedPageModule',
  },
  {
    path: 'meditate-guided',
    loadChildren: './meditate/meditate-guided/meditate-guided.module#MeditateGuidedPageModule',
  },
  {
    path: 'meditate-silent',
    loadChildren: './meditate/meditate-silent/meditate-silent.module#MeditateSilentPageModule',
  },
  {
    path: 'meditate-screen',
    loadChildren: './meditate/meditate-screen/meditate-screen.module#MeditateScreenPageModule',
  },
  {
    path: 'notes/:id',
    loadChildren: './notes/notes-form/notes-form.module#NotesFormPageModule',
  },
  {
    path: 'more-section',
    loadChildren: './more-section/more-section.module#MoreSectionPageModule',
  },
  {
    path: 'about-amt',
    loadChildren: './more-section/about-amt/about-amt.module#AboutAMTPageModule',
  },
  {
    path: 'mindfull-notices',
    loadChildren: './reminders/mindfull-notices/mindfull-notices.module#MindfullNoticesPageModule',
  },
  {
    path: 'the-sitting-still-app',
    loadChildren: './more-section/the-sitting-still-app/the-sitting-still-app.module#TheSittingStillAppPageModule',
  },
  {
    path: 'suggestions-for-practice',
    loadChildren: './more-section/suggestions-for-practice/suggestions-for-practice.module#SuggestionsForPracticePageModule',
  },
  {
    path: 'about-mindfulness',
    loadChildren: './more-section/about-mindfulness/about-mindfulness.module#AboutMindfulnessPageModule',
  },
  {
    path: 'about-books-eline-snel',
    loadChildren: './more-section/about-books-eline-snel/about-books-eline-snel.module#AboutBooksElineSnelPageModule',
  },
  {
    path: 'disclaimer',
    loadChildren: './more-section/disclaimer/disclaimer.module#DisclaimerPageModule',
  },
  { path: 'personalized-meditation', loadChildren: './reminders/personalized-meditation/personalized-meditation.module#PersonalizedMeditationPageModule' },
  { path: 'language', loadChildren: './more-section/language/language.module#LanguagePageModule' },
  { path: 'meditate-question', loadChildren: './meditate/meditate-question/meditate-question.module#MeditateQuestionPageModule' },
  { path: 'meditate-answer', loadChildren: './meditate/meditate-answer/meditate-answer.module#MeditateAnswerPageModule' },

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
